import React, { Suspense, lazy } from "react";
import {
  Navigate,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TimeTableApp = lazy(() => import("./pages/TimeTableApp"));
const TimeTableAutoGenerate = lazy(() =>
  import("./pages/TimeTableAutoGenerate")
);

const TimeTableAppTest = lazy(() =>
  import("./pages/TimeTableAppTest")
);

function App() {
  if (process.env.NODE_ENV === "production") {
    //DevNote(By Pramod): DO not Produce Logs Out put on Console in production
    console.log = function () {};
  }

  if (process.env.NODE_ENV === "development") {
    //DevNote(By Pramod): DO not Produce Logs Out put on Console in production
    //console.log = function() {};
  }
  //Testing
  // console.log("Afterprocess.env.NODE_ENV", process.env.NODE_ENV);

  return (
    <>
      <Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<TimeTableApp />} />
            <Route
              exact
              path="/autoGenerate"
              element={<TimeTableAutoGenerate />}
            />
            <Route
              exact
              path="/test"
              element={<TimeTableAppTest />}
            />
          </Routes>
        </Router>
      </Suspense>
      <ToastContainer autoClose={500} />
    </>
  );
}

export default App;
